import React from 'react';
import BtnArrow from "../../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../../assets/images/button_arrowbottom.svg"
import textLogo from "../../assets/images/svg/text/differnt_text_light.svg"

function AboutDifferences(props) {
    return (
        <>
            <section className='about-digital'>
                <div className='container'>

                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='digital_left'>
                                <span className='sub-title'>Why Choose<span className="differnt-textLogo-white"></span>?</span>
                                <h2 >Our Commitment: Delivering the Ultimate Experience</h2>
                                <p>Our solutions leverage custom software, prefabricated or custom-designed hardware, and cutting-edge technologies such as AI/ML to deliver unparalleled project results. With extensive experience and expertise, we ensure seamless adoption of new technologies.</p>
                                <a href="/services/" class="button-link">Explore All Services
                                    <div className="arrow_movement">
                                        <img src={BtnArrow} alt='' />
                                        <img src={BtnArrowbottom} alt='' />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='digital_right'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='process-box'>
                                            <h4>The Latest Technologies</h4>
                                            <p>Technology is chosen solely as the right tool for the job, not because we're locked into a specific vendor, language, or framework. Every deliverable is thoroughly tested and proven, crafted by a team of experts active in their respective fields.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='process-box'>
                                            <h4>A Collaborative Approach</h4>
                                            <p>We measure success as the entire project, not just our direct responsibilities. We invest additional time engaging with all stakeholders impacted by the project. We collaborate seamlessly with internal and external staff, vendors, and contractors to ensure comprehensive project success from all angles.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='process-box'>
                                            <h4>Dedicated To Your Success</h4>
                                            <p>With a dedicated account representative and project manager, we're fully committed to delivering the best. Every client is provided with the resources to ensure we consistently meet that objective.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='process-box'>
                                            <h4>High-Regulation Experience</h4>
                                            <p>While your project may not demand extensive regulatory expertise, it underscores our team's commitment to delivering excellence. In highly regulated sectors, errors are simply not tolerated. Regulatory bodies ensure the implementation of rigorous organization-wide processes to prevent mistakes. Hence, meticulousness isn't merely a part of our culture; it's integral to every aspect of our work.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutDifferences;